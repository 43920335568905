import styled from "styled-components";
import { Title, Subtitle } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";

export const Slide = styled.div`
    display: flex;
    position: relative;
    height: 500px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: auto;
    }
`;

export const LeftSide = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
    position: relative;
    width: 40%;
    background-color: ${(props) => props.theme.primary};
    padding: 40px;

	.ontop {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.inside-content {
		padding: 0 10px 0 50px;
	    h1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 3.3vw; //60px;
			line-height: 1.2;
			color: white;
			font-weight: 900;
			text-wrap: balance;
			text-transform: uppercase;
		}
		p {
			font-size: 18px;
			line-height: 1.5;
			color: white;
			text-wrap: balance;
		}
		@media (max-width: 860px) {
			h1 {
				font-size: 24px;
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		a {
			place-self: end;
			span {
				font-weight: 700;
			}
		}
		@media (max-width: 768px) {
			justify-content: center;
        }
	}

	.ntx-corner {
		width: 100px;
		height: 100px;
		color: white;
	}

	@media (max-width: 1044px) {
		padding: 20px;
		.inside-content p {
			font-size: 16px;
		}
	}
	@media (max-width: 768px) {
        width: 100%;
        align-items: center;
        text-align: center;
		.ntx-corner {
			display: none;
		}
		.inside-content {
			padding: 0;
			p {
				font-size: 14px;
			}
		}
    }
`;

export const RightSide = styled.div`
    width: 60%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
		object-position: left center;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
    }
`;

export const Login = styled.span`
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    color: white;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;
